import { Maybe } from '../../common/types'
import { Customer } from '../../containers/Customer/types'

export interface CreditInfo {
  invoice: {
    serviceName: string
    creditDecision: 'granted' | 'declined' | null
    creditDecisionRemarks: string
    message: string
    customerDataFound: 1 | 0
  }
  partPayment: {
    serviceName: string
    creditDecision: 'granted' | 'declined' | null
    creditDecisionRemarks: string
    message: string
    customerDataFound: 1 | 0
  }
}

export interface CustomerInfo {
  creditInfo: CreditInfo
  customer: Customer
}

export enum SearchType {
  info_search = 'info_search',
  credit_info = 'credit_info',
}

export interface InfoSearch {
  id: number
  searchMeta: InfoSearchMeta
  customer: Customer
  searchResults: InfoSearchResults
}

export interface InfoSearchMeta {
  userName: string
  customerIdentifier: string
  searchTimestamp: Date
}

export interface InfoSearchPaymentDefaults {
  amount: number
  info: Maybe<string>
  creditor: Maybe<string>
  source: Maybe<string>
  code: Maybe<string>
  type: Maybe<string>
  date: Maybe<Date>
  paidDate: Maybe<Date>
  validToDate: Maybe<Date>
  expireDate: Maybe<Date>
  caseTypeCode: Maybe<string>
}

export interface InfoSearchRegisters {
  name: string
  registered: boolean
  startDate: Date
  endDate: Maybe<Date>
}

export interface InfoSearchResults {
  paymentDefaults: Maybe<InfoSearchPaymentDefaults[]>
  registers: Maybe<InfoSearchRegisters[]>
}
