import React, { FunctionComponent } from "react"
import { Container, Content, IconWrapper, Message, Title } from "./styles"
import styled from "styled-components"
import { Icon } from "../icons/Icon"

const WarningContainer = styled(Container)`
  background-color: ${props => props.theme.colors.yellow100};
  color: ${props => props.theme.colors.black};
  height: 65px;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    padding: 1em 1em 1em 1em;
    height: 100px;
  }
`

interface WarningNotificationProps {
  height?: string
  title: string
  message: string
  children?: React.ReactNode
}

export const WarningNotification: FunctionComponent<WarningNotificationProps> = ({ title, message, children }) => {
  return (
    <WarningContainer>
      <IconWrapper>
        <Icon size="medium" icon={'error'} color="orange700" />
      </IconWrapper>
      <Content>
        <Title>{title}</Title>
        <Message>{message}</Message>
      </Content>
      {children}
    </WarningContainer>
  )
}