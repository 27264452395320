import { format } from 'date-fns'
import { find } from 'lodash'

import { Maybe, Time, TowingRecordRouteType, TowingRecordType } from '../../../../../../common/types'
import { SosFinishRouteType } from '../../../../../../components/SosSubForm/types'
import { createNewRoute } from '../../createNewRoute'
import { TowingRecordWithRoutes } from '../../types'
import { EditFinishRouteFormValues, FinishRoute } from './types'
import { repairShops } from './options'

export function getInitialValues(
  towingRecord: TowingRecordWithRoutes,
  batteryOption: Maybe<string>,
): EditFinishRouteFormValues {
  const route = setRouteDefaults(getRoute(towingRecord), towingRecord)

  const alValues = getAutoliittoValues(towingRecord, batteryOption)
  const sosValues = getSosValues(towingRecord, route)

  return {
    finishRoute: route,
    id: towingRecord.id,
    additionalInfo: towingRecord.sosServiceOrder ? '' : towingRecord.jobDetails.additionalInfo,
    serviceType: towingRecord.jobDetails.serviceType,
    disabledAddress: '',
    automobileAndTouringClubFinland: alValues,
    sosServiceOrder: sosValues,
    notes: towingRecord.jobDetails.notes,
    vehicleRegNo: towingRecord.vehicleDetails.registrationNumber,
    towingReason: towingRecord.jobDetails.towingReason,
    deliverable: 'Towage',
    cause: 'Kolari'
  }
}

function getRoute(towingRecord: TowingRecordWithRoutes): FinishRoute {
  const routeFound = find(towingRecord.routes, ['type', TowingRecordRouteType.finish]);

  return routeFound && routeFound.type === TowingRecordRouteType.finish
    ? { ...routeFound } as FinishRoute
    : createNewRoute(TowingRecordRouteType.finish, true) as FinishRoute;
}

function setRouteDefaults(route: FinishRoute, towingRecord: TowingRecordWithRoutes): FinishRoute {
  if (!route.arrivalDate) {
    route.arrivalDate = new Date();
  }
  
  if (!route.arrivalTime) {
    route.arrivalTime = format(new Date(), 'HH:mm') as unknown as Time;
  }
  
  if (towingRecord.automobileAndTouringClubFinland && towingRecord.type === TowingRecordType.autoliitto) {
    const repairShopId = towingRecord.automobileAndTouringClubFinland ? towingRecord.automobileAndTouringClubFinland.repairShopId : ''
    const repairShop = repairShops.find(({ id }) => id === repairShopId);

    if(repairShop && !route.id){
      route.address = repairShop.location.address
      route.city = repairShop.location.city
      route.coords.lat = repairShop.location.coordinates.lat
      route.coords.long = repairShop.location.coordinates.lon
      route.zipcode = repairShop.location.postcode
      route.finishedTime = null
    }
  }

  if (towingRecord.sosServiceOrder && towingRecord.type === TowingRecordType.sos) {
    const actualTimeOfCompletion = towingRecord.sosServiceOrder.jobTimestamps.completedAtDestination;

    route.finishedTime = actualTimeOfCompletion ? actualTimeOfCompletion.time : null;
    route.finishedDate = actualTimeOfCompletion ? actualTimeOfCompletion.date as Date : new Date();
  }

  return route;
}

function getAutoliittoValues(towingRecord: TowingRecordWithRoutes, batteryOption:  Maybe<string>) {
  if (towingRecord.automobileAndTouringClubFinland && towingRecord.type === TowingRecordType.autoliitto) {
    return {
      repairShopId: towingRecord.automobileAndTouringClubFinland.repairShopId,
      arcCodes: towingRecord.automobileAndTouringClubFinland.arcCodes,
      vehicleMileage: towingRecord.automobileAndTouringClubFinland.vehicleMileage,
      batteryService: {
        deliveredBattery: towingRecord.automobileAndTouringClubFinland.batteryService.deliveredBattery,
        paymentType: towingRecord.automobileAndTouringClubFinland.batteryService.paymentType,
        battery: batteryOption,
      },
    };
  }
  return null
}

function getSosValues(towingRecord: TowingRecordWithRoutes, route: FinishRoute) {
  if (towingRecord.sosServiceOrder && towingRecord.type === TowingRecordType.sos) {
    const actualTimeOfCompletion = towingRecord.sosServiceOrder.jobTimestamps.completedAtDestination
    return {
      keyLocation: towingRecord.sosServiceOrder.vehicleKeyLocation,
      locationType: SosFinishRouteType.REPAIR_SHOP,
      repairShopName: route.description || '',
      completedAtDestination: {
        date: actualTimeOfCompletion ? actualTimeOfCompletion.date : new Date(),
        time: actualTimeOfCompletion ? actualTimeOfCompletion.time : null,
      },
      vehicleMileage: towingRecord.sosServiceOrder.vehicleMileage,
      deliverable: towingRecord.sosServiceOrder.deliverable,
      cause: towingRecord.sosServiceOrder.cause,
    }
  }
  return null
}
