import { SubmitFormType } from './types'

const FORM_STATES = {
  ARRIVED: 'arrived',
  FINISHED: 'finished',
} as const

export const parseInitialSubmitFormFromParams = (): SubmitFormType => {
  const url = new URL(window.location.href)
  const selectedForm = (url.searchParams.get('form') as SubmitFormType) || FORM_STATES.ARRIVED

  return selectedForm === FORM_STATES.FINISHED ? FORM_STATES.FINISHED : FORM_STATES.ARRIVED
}
