import { FinnishBusinessIds } from 'finnish-business-ids'
import ssn from 'finnish-ssn'
import React, { useCallback, useState } from 'react'
import { useMutation } from 'react-apollo'
import styled, { css } from 'styled-components'

import { CustomerType, Maybe } from '../../common/types'
import { InfoSearchModal } from '../InfoSearch'
import { CheckboxInput, SelectInput, TextInput } from '../layout/form/input'
import { CenteredLoader } from '../responses/Loading'
import { setErrorNotifications, setFatalErrorNotification } from '../responses/setErrorNotifications'
import { CREDIT_INFO_QUERY, CreditInfoResponse } from './queries'
import { CustomerInfo, InfoSearch, SearchType } from './types'
import { ButtonColumn, ButtonRow, ModalHeader, ModalWindow, ModalWindowContent, Section, SectionColumn } from '../layout'
import { Button } from '../Button'
import { Form, Formik } from 'formik'
import { CustomerFormValues } from '../../containers/Customer/types'

interface SearchCustomerInfoProps {
  customerType: CustomerType
  searchString?: string | null
  handleResults: (creditInfo: CustomerInfo) => void
  handleInfoSearchResults?: (infoSearch: InfoSearch) => void
  setFieldValue?: (field: string, value: any) => void
  disabled?: boolean
  infoSearchModalButtonLabel?: string
  customerId?: Maybe<number>
  orderer?: Maybe<CustomerFormValues>
}

export const CustomSelectInput = styled(SelectInput)`
  ${props =>
    props.disabled &&
    css`
      color: ${props.theme.colors.grey500};
      background-color: ${props.theme.colors.grey300};
      border-color: ${props.theme.colors.grey300};
      font-weight: ${props.theme.fontWeight.normal};
    `}

`

export const SearchCustomerInfo: React.FunctionComponent<SearchCustomerInfoProps> = ({
  searchString = null,
  handleResults,
  handleInfoSearchResults,
  setFieldValue,
  disabled,
  customerType,
  infoSearchModalButtonLabel,
  customerId,
  orderer
}) => {
  const [isVisibleInfoSearchModal, setIsVisibleInfoSearchModal] = useState<boolean>(false)
  const [isVisibleConfirmatiohModal, setIsVisibleConfirmationModal] = useState<boolean>(false)
  const [consentValues, setConsentValues] = useState<CustomerConsentValues | null>(null)

  const validSsnOrBid =
    customerType === CustomerType.person
      ? ssn.validate(searchString ? searchString.toUpperCase() : null)
      : FinnishBusinessIds.isValidBusinessId(searchString || '')

  const validSearchString = searchString && validSsnOrBid ? true : !!customerId

  const setResult = useCallback((result: CustomerInfo)=>{
    handleResults(result);
    if(consentValues && result.customer.__typename === 'PersonCustomer' && setFieldValue){
      if(!result.customer.firstName && !(orderer && orderer.personCustomer && orderer.personCustomer.firstName)){
        setFieldValue('orderer.personCustomer.firstName', consentValues.firstName);
      }
      if(!result.customer.lastName && !(orderer && orderer.personCustomer && orderer.personCustomer.lastName)){
        setFieldValue('orderer.personCustomer.lastName', consentValues.lastName);
      }
    }
    setConsentValues(null);
  }, [setFieldValue, handleResults, consentValues, orderer])

  const [queryCreditInfo, { loading }] = useMutation<CreditInfoResponse>(CREDIT_INFO_QUERY, {
    onCompleted({ creditInfoSearch }) {
      if (creditInfoSearch.__typename === 'CreditInfoSearchSuccess') {
        setResult(creditInfoSearch.creditInfo)
      } else {
        setErrorNotifications({ data: creditInfoSearch })
      }

    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  const onSearch = useCallback(
    (searchType: SearchType, values?: CustomerConsentValues) => {
      console.log({ validSearchString, searchType, customerType, customerId })
      if (!validSearchString || !searchType) {
        return
      }
 
      if (searchType) {
        switch (searchType) {
          case SearchType.credit_info:
            if(values){
              setConsentValues(values);
            }

            queryCreditInfo({
              variables: {
                input: { 
                  customerIdentifier: searchString, 
                  customerId, 
                  firstName: values ? values.firstName : '', 
                  lastName: values ? values.lastName : '' 
                },
              },
            })
            break
          case SearchType.info_search:
            setIsVisibleInfoSearchModal(true)
            break
        }
      }
    },
    [searchString, queryCreditInfo, validSearchString, customerType, customerId]
  )

  const onSaveInfoSearchModal = (infoSearchResult: InfoSearch) => {
    if (handleInfoSearchResults) {
      handleInfoSearchResults(infoSearchResult)
      setIsVisibleInfoSearchModal(false)
    }
    return
  }

  const closeConfirmation = useCallback(()=>{
    setIsVisibleConfirmationModal(false);
  }, [setIsVisibleConfirmationModal])

  // @TODO: Implement credit info search for companies
  // For now just hide credit_info option
  return (
    <>
      <CenteredLoader loading={loading} />
      <CustomSelectInput
        name=""
        disabled={(disabled || !validSearchString || loading) as boolean}
        searchable={false}
        placeholder={'Hae'}
        options={
          customerType === 'person'
            ? [
                { value: SearchType.info_search, label: 'Osoite- ja maksuhäiriöt' },
                { value: SearchType.credit_info, label: 'Rahoituspäätös' },
              ]
            : [{ value: SearchType.info_search, label: 'Osoite- ja maksuhäiriöt' }]
        }
        onChange={(val) => {
          if(customerType === 'person' && val === SearchType.credit_info){
            return setIsVisibleConfirmationModal(true);
          }
          if (val) {
            onSearch(val as SearchType)
          }
        }}
      />
      {isVisibleInfoSearchModal && (
        <InfoSearchModal
          customerIdentifier={searchString || ''}
          customerType={customerType}
          close={() => setIsVisibleInfoSearchModal(false)}
          onSave={onSaveInfoSearchModal}
          saveButtonLabel={infoSearchModalButtonLabel && infoSearchModalButtonLabel}
          customerId={customerId}
        />
      )}
      {isVisibleConfirmatiohModal && (
        <SearchConfirmationModal
        onClose={closeConfirmation}
        onSearch={onSearch}
        />
      )}
    </>
  )
}

interface SearchConfirmationModalProps {
  onClose: ()=>void
  onSearch: (searchType: SearchType, values?: CustomerConsentValues)=>void
}

interface CustomerConsentValues {consent: boolean, firstName: string, lastName: string}

const SearchConfirmationModal: React.FunctionComponent<SearchConfirmationModalProps> = ({
  onClose,
  onSearch
}) => {


  const doSearch = useCallback((values: CustomerConsentValues)=>{
    onSearch(SearchType.credit_info, values);
    onClose();
  }, [onClose, onSearch])

  return (
    <MyContainer>
      <ModalWindow border={'3px solid gray'}>
        <ModalHeader>{'Asiakkaan tietojen tarkastus'}</ModalHeader>
        <ModalWindowContent>
        <Formik
        initialValues={{ consent: false, firstName: '', lastName: '' }}
        onSubmit={(values: CustomerConsentValues) => {
          doSearch(values)
        }}
        render={({ values, handleSubmit }) => {
          return (
            <Form>
              <Section mobileGap="0.5rem" height="auto" columns={1}>
                <SectionColumn>
                  <TextInput name='firstName' label="Etunimi" />
                </SectionColumn>
                <SectionColumn>
                  <TextInput name='lastName' label="Sukunimi" />
                </SectionColumn>
              </Section>
              <Section mobileGap="0.5rem" height="auto" columns={1}>
                <SectionColumn flexDirection="column">
                  <CheckboxInput name="consent" label="Olen tarkastanut asiakkaan henkilöllisyyden"/>
                </SectionColumn>
              </Section>
              <ButtonRow padding="0">
                <ButtonColumn>
                  <Button
                    category={'save'}
                    label={'Hae'}
                    disabled={!(values.consent && values.firstName && values.lastName)}
                    onClick={handleSubmit}
                  />
                </ButtonColumn>
                <ButtonColumn>
                  <Button category="cancel" label={'Peruuta'} onClick={onClose} />
                </ButtonColumn>
              </ButtonRow>
            </Form>
          
          )}} />
        </ModalWindowContent>
      </ModalWindow>
    </MyContainer>
  )
}

const MyContainer = styled.div`
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100%;
`