import React, { useContext } from 'react'
import { TowingRecord } from '../../ViewTowingRecord/types'
import { RouteForm, AutoliittoSeparatorLine } from '../routes/subforms/styled'
import { RouteWrapper, BigButtonSection, Text, Section } from '../../../../components/layout'
import { RouteHeader } from '../routes/subforms/RouteHeader'
import { Styling } from '../../../../components/Styling'
import { Button } from '../../../../components/Button'
import { StateStore } from '../../../StoreProvider'
import { ACS } from '../../../../common/AccessControl'
import { EditRouteSubforms } from '../routes/types'

import { IconWrapper } from '../../../../components/notification/styles'
import { useHistoryPush } from '../../../../components/router'
import { createRouteMenuConfig, finishRouteAddressRequired } from '../routes/createRouteMenuConfig'
import { WarningNotification } from '../../../../components/notification/warningNotification'
import { TowingRecordType } from '../../../../common/types'
import { getIncompleteRoutes } from '../routes/getIncompleteRoutes'

interface EndTowingRecordProps {
  onCancel: () => void
  towingRecord: TowingRecord
  setNextForm: (id: EditRouteSubforms, type: 'end' | 'cancel') => void
}

export const EndTowingRecord: React.FunctionComponent<EndTowingRecordProps> = ({
  onCancel,
  towingRecord,
  setNextForm,
}) => {
  const historyPush = useHistoryPush()
  const { state } = useContext(StateStore)
  const accessControl = new ACS(state.currentUser).setTowingRecord(towingRecord)

  const useExtendedRoutes = state.settings ? state.settings.modules.useExtendedRoutes : false

  const finishRouteRequired = finishRouteAddressRequired(towingRecord.jobDetails.serviceType, towingRecord.type)
  const routeMenuConfig = createRouteMenuConfig(
    towingRecord.routes,
    finishRouteRequired,
    towingRecord.type,
    useExtendedRoutes
  )

  const incompleteRoutes = getIncompleteRoutes(towingRecord, routeMenuConfig, useExtendedRoutes)

  const disableEndTowingRecord =
    !accessControl.canTowingRecord('finalize') ||
    (towingRecord.type === TowingRecordType.autoliitto && incompleteRoutes.length > 0)

  return (
    <RouteForm>
      <RouteWrapper>
        <RouteHeader label={`Lopeta työ`} regNo={towingRecord.vehicleDetails.registrationNumber} cancel={onCancel} />
        <Styling padding="0 2rem" paddingMobile="0 0.5rem">
          <BigButtonSection>
            <Button
              category="on"
              label="Lopeta työ"
              mobileLabel="Lopeta työ"
              onClick={() => {
                setNextForm('cancel-or-end-towing-record', 'end')
              }}
              size="m"
              fontWeight="normal"
              maxWidth="100%"
              disabled={disableEndTowingRecord}
            />
            <Button
              category="cancel"
              label="Työ peruutettu"
              onClick={() => {
                setNextForm('cancel-or-end-towing-record', 'cancel')
              }}
              size="m"
              fontWeight="normal"
              maxWidth="100%"
            />
          </BigButtonSection>
          <AutoliittoSeparatorLine marginTop="1rem" marginBottom="1rem" />
          {!accessControl.canTowingRecord('finalize') && (
            <Text style={{ fontWeight: 'bold', marginBottom: "1rem" }}>
              Huom! Paikan päällä suoritetussa työssä pitää olla kirjattu kohteen tiedot ja hinauksissa kohteen sekä
              määränpään tiedot ennen kuin työ voidaan merkitä valmiiksi
            </Text>
          )}
          <Section height="24px" columns={1}>
            {incompleteRoutes.map((r, i) => (
              <WarningNotification key={i} title={''} message={`${r.label} reittipisteeltä puuttuu tietoja`}>
                <IconWrapper className="w-100%">
                  <Button
                    key={i}
                    onClick={() => historyPush(`/towing-record/${towingRecord.id}/${r.routeId}${r.subform}`)}
                    category={'new'}
                  >
                    Muokkaa
                  </Button>
                </IconWrapper>
              </WarningNotification>
            ))}
          </Section>
        </Styling>
      </RouteWrapper>
    </RouteForm>
  )
}
