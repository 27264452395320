import Maybe from 'graphql/tsutils/Maybe'
import React, { useRef, useState } from 'react'

export const useGetCurrentTimeDifference = (
  date: Maybe<Date>,
  { onUpdate }: { onUpdate: (difference: number, timerId: Maybe<number>) => void }
) => {
  const [difference, setDifference] = useState(() => {
    return date ? dateDiffInSeconds(date) : 0
  })
  const timerId = useRef<Maybe<number>>(null)

  function dateDiffInSeconds(a: Date) {
    const t1 = a.getTime()
    const t2 = new Date().getTime()
    //This should not happen where jobStarted is in the future
    if (t1 > t2) {
      return -Math.round(Math.abs(t1 - t2) / 1000)
    }
    return Math.round(Math.abs(t1 - t2) / 1000)
  }

  React.useEffect(() => {
    if (timerId.current) return

    timerId.current = setInterval(() => {
      if (!date) return

      const diff = dateDiffInSeconds(date)
      onUpdate(diff, timerId.current)
      setDifference(diff)

    }, 1000)

    return () => {
      if (timerId.current) {
        clearInterval(timerId.current)
        timerId.current = null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { difference }
}
