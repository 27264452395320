import React, { FunctionComponent } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { NotificationDelay } from '../../common/types'
import { Icon } from '../icons/Icon'
import { Container, Content, IconWrapper, Message, Title } from './styles'
import { dismissToast } from '.'

export const setErrorNotification = (title: string, message: string, noDelay?: boolean) => {
  if (!toast.isActive(title)) {
    toast.error(<ErrorNotification title={title} message={message} />, {
      autoClose: false,
      closeButton: false,
      delay: 0,
      toastId: title,
    })
    if(noDelay){
      dismissToast(title, 0)
    }else{
      dismissToast(title, NotificationDelay.errorNotificationDelay)

    }
  }
}

interface ErrorNotificationProps {
  title: string
  message: string
  children?: React.ReactNode
}

export const ErrorNotification: FunctionComponent<ErrorNotificationProps> = ({ title, message, children }) => {
  return (
    <ErrorContainer>
      <IconWrapper>
        <Icon size="big" icon={'error'} color="red900" />
      </IconWrapper>
      <Content>
        <Title>{title}</Title>
        <Message>{message}</Message>
      </Content>
      {children}
    </ErrorContainer>
  )
}

const ErrorContainer = styled(Container)`
  background-color: ${props => props.theme.colors.red100};
  color: ${props => props.theme.colors.red900};
  border-bottom: 1px solid ${props => props.theme.colors.red500};
`