import React, { useState } from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'

import { CustomerType, Maybe } from '../../../common/types'
import { HalfButton } from '../../../components/Button'
import { CreditDecision } from '../../../components/CreditDecision'
import {
  ButtonInputWrapper,
  Column,
  FormSubGroup,
  FormSubGroupHeader,
  FormSubGroupHeading,
  FormSubGroupWrapper,
  Row,
  Section,
  SectionColumn,
} from '../../../components/layout'
import {
  BusinessIdInput,
  CheckboxInput,
  PersonalIdentityCodeInput,
  PhoneInput,
  RadioButtonGroup,
  TextInput,
} from '../../../components/layout/form/input'
import { CustomSelectInput, SearchCustomerInfo } from '../../../components/SearchCustomerInfo'
import { CreditInfo } from '../../../components/SearchCustomerInfo/types'
import { CustomerOriginatorApp } from '../../../functions/graphqlApi/types/customer'
import { translateInputOptions } from '../../../util/translateInputOptions'
import { DateSectionColumn } from '../../TowingOrderPage/EditTowingOrder/TowingOrderDetailsSubFormContents'
import { CustomerAutoSuggest } from '../CustomerAutoSuggest'
import { ordererTypeOptions } from '../options'
import { toCustomerInput } from '../toCustomerInput'
import { companyToOrderer, personToOrderer } from './customerToOrderer'
import { CompanyCustomer, Customer, CustomerFormValues, PersonCustomer } from '../types'
import { OwnersHoldersTrafi } from '../../../components/VehicleDetails/types'
import styled from 'styled-components'
import { getCustomerFromOwnerHolder } from './queries'
import { useApolloClient } from 'react-apollo'
import { setErrorNotification } from '../../../components/notification'

interface CustomerFormFieldsProps {
  orderer: Maybe<CustomerFormValues>
  clearOrderer: () => void
  editCustomer: () => void
  onUpdate: (customer: CustomerFormValues) => void
  ssn: string
  businessId: string
  setFieldValue: (field: string, value: any) => void
  setOrdererFromTrafi: (customer: CustomerFormValues) => void
  isSos?: boolean
  infoSearchModalButtonLabel?: string
  ownersHolders: OwnersHoldersTrafi[]
}

export interface Orderer {
  id: Maybe<number>
  type: CustomerType
}

const CustomerFormFieldsIntl: React.FunctionComponent<CustomerFormFieldsProps & InjectedIntlProps> = ({
  orderer,
  clearOrderer,
  editCustomer,
  onUpdate,
  intl,
  ssn,
  businessId,
  isSos = false,
  infoSearchModalButtonLabel,
  ownersHolders,
  setFieldValue,
  setOrdererFromTrafi,
}) => {
  const [creditDecision, setCreditDecision] = useState<CreditInfo | null>(null)
  //const [infoSearchResponse, setInfoSearchResponse] = useState<InfoSearch | null>(null)  // The useState was moved to SearchCustomerInfo
  const [isTrafiCustomer, setIsTrafiCustomer] = useState<boolean>(false)
  const { formatMessage } = intl
  const translatedOrdererTypesOptions = translateInputOptions(ordererTypeOptions, formatMessage)

  const isCustomer = orderer && orderer.id ? true : false
  const isSosCustomer = (isCustomer &&
    orderer &&
    orderer.personCustomer &&
    orderer.personCustomer.originatorApp === CustomerOriginatorApp.sos) as boolean
  const disableSearches = isSosCustomer ? false : isCustomer || isTrafiCustomer

  function mergeOrdererInfoSearchProperties(data: PersonCustomer, orderer: Maybe<CustomerFormValues>): Customer {
    // set here what we want to use from the infosearch (use info search values if set, if not use orderer values if set, else empty)

    const relevantFields = {
      __typename: data.__typename,
      firstName: data.firstName
        ? data.firstName
        : orderer && orderer.personCustomer && orderer.personCustomer.firstName
        ? orderer.personCustomer.firstName
        : '',
      lastName: data.lastName
        ? data.lastName
        : orderer && orderer.personCustomer && orderer.personCustomer.lastName
        ? orderer.personCustomer.lastName
        : '',
      id: data.id,
      address: data.address
        ? data.address
        : orderer && orderer.personCustomer && orderer.personCustomer.address
        ? orderer.personCustomer.address
        : '',
      city: data.city
        ? data.city
        : orderer && orderer.personCustomer && orderer.personCustomer.city
        ? orderer.personCustomer.city
        : '',
      zipcode: data.zipcode
        ? data.zipcode
        : orderer && orderer.personCustomer && orderer.personCustomer.zipcode
        ? orderer.personCustomer.zipcode
        : '',
      type: data.type ? data.type : CustomerType.person,
      displayInCustomerRegistry: orderer ? orderer.displayInCustomerRegistry : false
    }

    const result = orderer && orderer.personCustomer ? { ...orderer.personCustomer, ...relevantFields } : data

    return result
  }

  const searchStr = ssn
    ? ssn
    : orderer && orderer.personCustomer && orderer.personCustomer.maskedSsn
    ? orderer.personCustomer.maskedSsn
    : ''

  const client = useApolloClient()

  console.log('orderer', orderer)

  return (
    <>
      <FormSubGroupWrapper>
        <FormSubGroupHeader>
          <Row justify="space-between">
            <Column>
              <FormSubGroupHeading>Tilaajan tiedot</FormSubGroupHeading>
            </Column>
            <Column>
              <RadioButtonGroup
                label={''}
                disabled={isCustomer}
                name="orderer.type"
                options={translatedOrdererTypesOptions}
              ></RadioButtonGroup>
            </Column>
          </Row>
        </FormSubGroupHeader>
        {creditDecision && <CreditDecision creditInfo={creditDecision} />}
        <FormSubGroup>
          {orderer && orderer.type === CustomerType.company && (
            <Section>
              <SectionColumn>
                <ButtonInputWrapper>
                  <BusinessIdInput label={'Y-tunnus'} name="orderer.companyCustomer.businessId" disabled={isCustomer} />
                  <SearchCustomerInfo
                    customerType={CustomerType.company}
                    handleResults={({ creditInfo, customer }) => {
                      setCreditDecision(creditInfo)
                      onUpdate(toCustomerInput(customer))
                      setIsTrafiCustomer(false)
                    }}
                    handleInfoSearchResults={data => {
                      if (data.customer && data.customer.__typename === 'CompanyCustomer') {
                        console.log('data', data)
                        setCreditDecision(null)
                        onUpdate(toCustomerInput(data.customer))
                        setIsTrafiCustomer(false)
                      }
                    }}
                    searchString={businessId}
                    infoSearchModalButtonLabel={infoSearchModalButtonLabel && infoSearchModalButtonLabel}
                  />
                </ButtonInputWrapper>
              </SectionColumn>
              <SectionColumn>
                <CustomerAutoSuggest
                  label={'Yrityksen nimi'}
                  name="orderer.companyCustomer.companyName"
                  searchType="company"
                  placeholder="Valitse..."
                  onChange={onChangesuggestion => {
                    if (
                      onChangesuggestion &&
                      'object' in onChangesuggestion &&
                      onChangesuggestion.object != null &&
                      onChangesuggestion.object.__typename === 'CompanyCustomer'
                    ) {
                      onUpdate(companyToOrderer({ customer: onChangesuggestion.object as CompanyCustomer}))
                    }
                  }}
                  required={isSos}
                />
              </SectionColumn>
              <SectionColumn>
                <TextInput label={'Yhteyshenkilö'} name="orderer.companyCustomer.contactPerson" disabled={isCustomer} />
              </SectionColumn>
              <SectionColumn>
                <PhoneInput
                  label={'Yhteyshenkilön puhelinnumero'}
                  name="orderer.companyCustomer.phone"
                  disabled={isCustomer}
                  required={isSos}
                />
              </SectionColumn>
              <SectionColumn>
                <TextInput
                  label={'Yhteyshenkilön sähköposti'}
                  name="orderer.companyCustomer.contactPersonEmail"
                  disabled={isCustomer}
                  type="email"
                />
              </SectionColumn>
              <DateSectionColumn reverse>
                <HalfButton
                  category="cancelSecondary"
                  label="Tyhjennä"
                  onClick={() => {
                    setCreditDecision(null)
                    clearOrderer()
                  }}
                  size="s"
                />
                {orderer && orderer.id ? (
                  <HalfButton
                    category="editSecondary"
                    label="Muokkaa"
                    onClick={editCustomer}
                    disabled={!orderer.id}
                    size="s"
                  />
                ) : (
                  <HalfContainer>
                    <CustomSelectInput
                      unsetAfterSelect
                      name=""
                      disabled={((ownersHolders.length < 1) as boolean) || isCustomer}
                      searchable={false}
                      placeholder={'Omistaja/Haltija'}
                      options={ownersHolders}
                      onChange={async (val: any) => {
                        if (val) {
                          clearOrderer()
                          setFieldValue('orderer.type', (val as CustomerFormValues).type)
                          setOrdererFromTrafi(val as CustomerFormValues)
                          // no need to disable the business id field, set to false
                          if (val.personCustomer && val.personCustomer.ssn.length > 0) {
                            setIsTrafiCustomer(true)
                          } else {
                            setIsTrafiCustomer(false)
                          }

                          if (val.ownerHolderId) {
                            const customer = await getCustomerFromOwnerHolder(client, val.ownerHolderId)
                            if (customer.__typename === 'GetCustomerSuccess') {
                              if (orderer.personCustomer && !orderer.personCustomer.ssn) {
                                setOrdererFromTrafi(toCustomerInput(customer.customer))
                              }
                            } else {
                              setErrorNotification('Virhe', 'Omistajatietoa ei saatavilla. Täytä tiedot käsin')
                            }
                          }
                        }
                      }}
                    />
                  </HalfContainer>
                )}
              </DateSectionColumn>
            </Section>
          )}
          {!orderer ||
            (orderer.type === CustomerType.person && (
              <Section>
                <SectionColumn>
                  <ButtonInputWrapper>
                    {orderer.personCustomer && orderer.personCustomer.maskedSsn ? (
                      <TextInput
                        label={'Henkilötunnus'}
                        name="orderer.personCustomer.maskedSsn"
                        disabled={disableSearches}
                      />
                    ) : (
                      <PersonalIdentityCodeInput
                        label={'Henkilötunnus'}
                        name="orderer.personCustomer.ssn"
                        disabled={disableSearches}
                      />
                    )}

                    <SearchCustomerInfo
                      customerType={CustomerType.person}
                      handleResults={({ creditInfo, customer }) => {
                        setCreditDecision(creditInfo)
                        onUpdate(toCustomerInput(mergeOrdererInfoSearchProperties(customer as PersonCustomer, orderer)))
                        setIsTrafiCustomer(false)
                      }}
                      handleInfoSearchResults={data => {
                        if (data.customer && data.customer.__typename === 'PersonCustomer') {
                          setCreditDecision(null)
                          onUpdate(toCustomerInput(mergeOrdererInfoSearchProperties(data.customer, orderer)))
                          setIsTrafiCustomer(false)
                        }
                      }}
                      setFieldValue={setFieldValue}
                      searchString={searchStr}
                      infoSearchModalButtonLabel={infoSearchModalButtonLabel && infoSearchModalButtonLabel}
                      customerId={orderer.id}
                      orderer={orderer}
                    />
                  </ButtonInputWrapper>
                </SectionColumn>
                <SectionColumn>
                  <CustomerAutoSuggest
                    label={isSosCustomer ? 'Nimi' : 'Sukunimi'}
                    name="orderer.personCustomer.lastName"
                    searchType="person"
                    disabled={isCustomer || isSosCustomer}
                    placeholder="Valitse..."
                    onChange={onChangesuggestion => {
                      if (
                        onChangesuggestion &&
                        'object' in onChangesuggestion &&
                        onChangesuggestion.object != null &&
                        onChangesuggestion.object.__typename === 'PersonCustomer'
                      ) {
                        onUpdate(personToOrderer({ customer: onChangesuggestion.object as unknown as PersonCustomer }))
                      }
                    }}
                    required={isSos}
                  />
                </SectionColumn>
                {!isSosCustomer && (
                  <SectionColumn>
                    <TextInput label={'Etunimet'} name="orderer.personCustomer.firstName" disabled={isCustomer} />
                  </SectionColumn>
                )}

                <SectionColumn>
                  <PhoneInput
                    label={'Puhelinnumero'}
                    name="orderer.personCustomer.phone"
                    disabled={isCustomer}
                    required={isSos}
                  />
                </SectionColumn>
                {!isSosCustomer && (
                  <>
                    <SectionColumn>
                      <TextInput
                        label={'Sähköposti'}
                        name="orderer.personCustomer.email"
                        disabled={isCustomer}
                        type="email"
                      />
                    </SectionColumn>
                    <DateSectionColumn reverse>
                      <HalfButton
                        category="cancelSecondary"
                        label="Tyhjennä"
                        onClick={() => {
                          setCreditDecision(null)
                          clearOrderer()
                          setIsTrafiCustomer(false)
                        }}
                        size="s"
                      />
                      {orderer && orderer.id ? (
                        <HalfButton
                          category="editSecondary"
                          label="Muokkaa"
                          onClick={editCustomer}
                          disabled={!orderer.id}
                          size="s"
                        />
                      ) : (
                        <HalfContainer>
                          <CustomSelectInput
                            unsetAfterSelect
                            name=""
                            disabled={((ownersHolders.length < 1) as boolean) || isCustomer}
                            searchable={false}
                            placeholder={'Omistaja/Haltija'}
                            options={ownersHolders}
                            onChange={async (val: any) => {
                              if (val) {
                                clearOrderer()
                                setFieldValue('orderer.type', (val as CustomerFormValues).type)
                                setOrdererFromTrafi(val as CustomerFormValues)

                                // disable ssn field if we have an ssn from trafi
                                if (val.personCustomer && val.personCustomer.ssn.length > 0) {
                                  setIsTrafiCustomer(true)
                                } else {
                                  setIsTrafiCustomer(false)
                                }

                                if (val.ownerHolderId) {
                                  const customer = await getCustomerFromOwnerHolder(client, val.ownerHolderId)
                                  if (customer.__typename === 'GetCustomerSuccess') {
                                    if (orderer.personCustomer && !orderer.personCustomer.ssn) {
                                      setOrdererFromTrafi(toCustomerInput(customer.customer))
                                    }
                                  } else {
                                    setErrorNotification('Virhe', 'Omistajatietoa ei saatavilla. Täytä tiedot käsin')
                                  }
                                }
                              }
                            }}
                          />
                        </HalfContainer>
                      )}
                    </DateSectionColumn>
                  </>
                )}

              </Section>
            ))}
            {/* visible: !$root.Visual.isUserCallCenter2() */}
            {(orderer && orderer.id === null) || (orderer && !orderer.displayInCustomerRegistry) ? 
              <SectionColumn>
                <CheckboxInput label='Tallenna asiakasrekisteriin' name={'orderer.addToRegistry'} />
              </SectionColumn>
            : null }
        </FormSubGroup>
      </FormSubGroupWrapper>
    </>
  )
}

export const CustomerFormFields = injectIntl(CustomerFormFieldsIntl)

export const HalfContainer = styled('div')`
  width: 50%;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    width: calc(50% - 0.125rem);
  }

  & div > .Select__control {
    background-color: ${props => props.theme.colors['blue800']};
    border: 0;
  }

  & div > .Select__control--is-disabled {
    background-color: #e2e4e6;
  }

  & div:last-of-type > div {
    border-radius: ${props => props.theme.radius.large};
  }

  & .Select__value-container {
    justify-content: center;
  }

  & .Select__placeholder {
    color: #fff;
    font-weight: ${props => props.theme.fontWeight.bold};
    font-size: 1rem;
    justify-self: center;
  }

  & .Select__indicators {
    visibility: hidden;
    display: none;
  }

  & .Select__menu {
    width: max-content;
    @media (max-width: ${props => props.theme.screenSize.tablet}) {
      width: 200%;
    }
    @media (max-width: ${props => props.theme.screenSize.mobile}) {
      width: 100%;
    }
    right: 0;
    border-radius: ${props => props.theme.radius.medium}!important;
  }
`
