import gql from 'graphql-tag'
import { UnauthorizedError } from '../../errorTypes'
import { Customer } from './types'

export const query = gql`
  query Customers($searchString: String, $type: String) {
    customers(searchString: $searchString, type: $type) {
      ... on GetCustomersSuccess {
        customers {
          id
          phone
          identifier
          extraInfo
          co
          address
          zipcode
          city
          phone
          eInvoiceAddress
          eInvoiceOperator
          paymentTerms
          deliveryMethod
          email
          additionalInfo
          notificationMethod
          ... on CompanyCustomer {
            companyName
            businessId
            contactPerson
            contactPersonEmail
            displayInCustomerRegistry
          }
          ... on PersonCustomer {
            ssn
            firstName
            lastName
            displayInCustomerRegistry
          }
        }
      }
    }
  }
`

export interface CustomersResponse {
  customers: CustomersOutput
}

export type CustomersOutput = CustomersSuccess | UnauthorizedError

interface CustomersSuccess
  extends Readonly<{
    __typename: 'GetCustomersSuccess'
    customers: Customer[]
  }> {}
