import { useMutation } from '@apollo/react-hooks'
import { format } from 'date-fns'
import { Formik, getIn } from 'formik'
import React, { useContext, useState } from 'react'
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl'

import {
  Maybe,
  Time,
  TowingRecordRouteType,
  TowingRecordServiceType,
  TowingRecordType,
  TypeIdentifier,
} from '../../../../../../common/types'
import { Button } from '../../../../../../components/Button'
import { ScrollToError } from '../../../../../../components/layout/form/ScrollToError'
import { setErrorNotification, setSuccessNotification } from '../../../../../../components/notification'
import { Loading } from '../../../../../../components/responses'
import {
  setErrorNotifications,
  setFatalErrorNotification,
} from '../../../../../../components/responses/setErrorNotifications'
import { useHistoryPush } from '../../../../../../components/router'
import { StateStore } from '../../../../../StoreProvider'
import { SetTowingRecordType } from '../../../../ViewTowingRecord/types'
import { GeoLocateAddressData, TowingRecordWithRoutes } from '../../types'
import { getRouteArrivalDateTimeByType, getRouteFinishedDateTimeByType, validateRouteTimes } from '../../validateRouteTimes'
import { ButtonContainer, RouteForm } from '../styled'
import { EndRoute } from './EndRoute'
import { getInitialValues } from './getInitialValues'
import { EDIT_END_ROUTE_MUTATION, EditEndRouteResponse } from './mutation/mutation'

import { EditEndRouteFormValues } from './types'
import { toMutationVariables } from './mutation/toMutationVariables'

interface EditTowingRecordRoutesProps {
  onCancel: () => void
  towingRecord: TowingRecordWithRoutes
  setTowingRecord?: SetTowingRecordType
}

const routeMessages = defineMessages({
  route_edit_notification_success_title: {
    id: 'route.edit.notification.success.title',
    defaultMessage: 'Route updated',
  },
  route_edit_notification_success_message: {
    id: 'route.edit.notification.success.message',
    defaultMessage: 'Route updated successfully.',
  },
})

export const RoutesIntl: React.FunctionComponent<EditTowingRecordRoutesProps & InjectedIntlProps> = ({
  onCancel,
  towingRecord,
  intl,
  setTowingRecord,
}) => {
  const historyPush = useHistoryPush()
  const { formatMessage } = intl
  const {
    state: { settings },
  } = useContext(StateStore)

  const [editTowingRecord, { loading }] = useMutation<EditEndRouteResponse>(EDIT_END_ROUTE_MUTATION, {
    onCompleted(test) {
      const { editEndRoute } = test
      if (editEndRoute.__typename === 'EditTowingRecordSuccess') {
        setSuccessNotification(
          formatMessage(routeMessages.route_edit_notification_success_title),
          formatMessage(routeMessages.route_edit_notification_success_message)
        )
        if (setTowingRecord) {
          setTowingRecord(editEndRoute.towingRecord, 'view-towing-record')
          return
        }
        historyPush(`/towing-record/${towingRecord.id}`)
      } else {
        setErrorNotifications({ data: editEndRoute })
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  const stations = settings ? settings.stations : []
  const useExtendedRoutes = settings && settings.modules.useExtendedRoutes ? true : false
  const initialValues = getInitialValues(towingRecord, stations)
  const isAutoliitto = towingRecord.typeIdentifier === TypeIdentifier.autoliitto
  const isSos = towingRecord.type === TowingRecordType.sos
  const [isLoading, setIsloading] = useState<boolean>(false)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values: EditEndRouteFormValues) => {
        const routes = [
          getRouteArrivalDateTimeByType(TowingRecordRouteType.start, towingRecord.routes),
          getRouteArrivalDateTimeByType(TowingRecordRouteType.arrival, towingRecord.routes),
        ]
        if (
          towingRecord.type === TowingRecordType.default &&
          towingRecord.jobDetails.serviceType !== TowingRecordServiceType.ROAD_SERVICE
        ) {
          routes.push(getRouteArrivalDateTimeByType(TowingRecordRouteType.finish, towingRecord.routes))
        }
        if (
          towingRecord.type === TowingRecordType.autoliitto &&
          towingRecord.jobDetails.serviceType !== TowingRecordServiceType.ROAD_SERVICE
        ) {
          routes.push(getRouteArrivalDateTimeByType(TowingRecordRouteType.finish, towingRecord.routes))
        }
        if (
          towingRecord.type === TowingRecordType.sos &&
          towingRecord.jobDetails.serviceType !== TowingRecordServiceType.ROAD_SERVICE
        ) {
          routes.push(getRouteFinishedDateTimeByType(TowingRecordRouteType.arrival, towingRecord.routes))
          routes.push(getRouteArrivalDateTimeByType(TowingRecordRouteType.finish, towingRecord.routes))
          routes.push(getRouteFinishedDateTimeByType(TowingRecordRouteType.finish, towingRecord.routes))
        }
        try {
          validateRouteTimes(
            { date: values.endRoute.arrivalDate, time: values.endRoute.arrivalTime, type: values.endRoute.type },
            routes
          )
        } catch (e) {
          return setErrorNotification('Virhe', e.message)
        }

        if (isSos || isAutoliitto) {
          const { endRoute } = values
          if (!endRoute.address) {
            return setErrorNotification('Virhe', 'Valitse asemapaikka')
          }
        }
        await editTowingRecord({ variables: toMutationVariables(values) })
      }}

      render={({ setFieldValue, values }) => {
        const setValue = (field: string, value: any) => {
          setFieldValue(field, value)
        }

        const getValue = (fieldName: string) => {
          const value = getIn(values, fieldName)
          return value
        }

        const setRouteLocation = (locationData: Maybe<GeoLocateAddressData>) => {
          if (locationData) {
            setValue(`endRoute.address`, locationData.address)
            setValue(`endRoute.city`, locationData.city)
            setValue(`endRoute.coords.lat`, locationData.coords.lat)
            setValue(`endRoute.coords.long`, locationData.coords.long)
            setValue(`endRoute.zipcode`, locationData.zipcode)
          }
        }

        const updateRouteDate = () => {
          const value = (format(new Date(), 'HH:mm') as unknown) as Time
          setValue(`endRoute.date`, new Date())
          setValue(`endRoute.time`, value)
        }

        const setGeolocatedField = (geolocated: boolean) => {
          setValue('endRoute.geolocated', geolocated)
        }

        const clear = () => {
          setFieldValue('endRoute.address', '')
          setFieldValue('endRoute.city', '')
          setFieldValue('endRoute.time', '')
          setFieldValue('endRoute.date', '')
          setFieldValue('endRoute.coords.lat', null)
          setFieldValue('endRoute.coords.long', null)
          setFieldValue('endRoute.geolocated', false)
        }

        return (
          <RouteForm>
            <EndRoute
              regNo={towingRecord.vehicleDetails.registrationNumber}
              onCancel={() => onCancel()}
              setRouteLocation={setRouteLocation}
              updateRouteDate={() => updateRouteDate}
              showCustomAddressInitially={initialValues.endRoute.address ? true : false}
              initialValues={initialValues}
              isAutoliitto={isAutoliitto}
              clear={clear}
              setGeolocatedField={setGeolocatedField}
              setLoading={setIsloading}
              setValue={setValue}
              getValue={getValue}
              isSos={isSos}
              useExtendedRoutes={useExtendedRoutes}
              disableEtaBtn={values.endRoute.address === '' || values.endRoute.city === ''}
              stationId={towingRecord.stationId}
            />

            <ButtonContainer floatAtBottom>
              <Loading loading={loading || isLoading} />
              <Button
                category="save"
                size="l"
                label="Tallenna lopetus"
                mobileLabel="Tallenna"
                submit
                maxWidth="100%"
              ></Button>
            </ButtonContainer>
            <ScrollToError />
          </RouteForm>
        )
      }}
    />
  )
}

export const EndRoutePage = injectIntl(RoutesIntl)
