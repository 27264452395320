import { TowingRecordType } from '../../../../common/types'
import { TowingRecord, TowingRecordRoute } from '../../ViewTowingRecord/types'
import { RouteMenuConfig } from './createRouteMenuConfig'
import { disableFinishRoute } from './RoutesMenu'

export type IncompleteRoutes = RouteMenuConfig & {
  missingRequiredFields: boolean
  subform: string
}

export function getIncompleteRoutes(
  towingRecord: TowingRecord,
  menuConfig: RouteMenuConfig[],
  useExtendedRoutes: boolean
): IncompleteRoutes[] {
  const result = menuConfig.map(route => {
    const originalRoute = towingRecord.routes.find(({ type }) => type === route.routeId)
    return setRoute(originalRoute, route)
  })

  return result.filter(route => filterRoute(route, towingRecord, useExtendedRoutes))
}

function setRoute(originalRoute: TowingRecordRoute | undefined, route: RouteMenuConfig) {
  if (typeof originalRoute === 'undefined') {
    return {
      ...route,
      missingRequiredFields: true,
      subform: '',
    }
  }

  if (route.timeOfCompletionRequired) {
    return {
      ...route,
      missingRequiredFields: (!originalRoute.arrivalTime || !originalRoute.arrivalDate ||
                              !originalRoute.finishedTime || !originalRoute.finishedDate ||
                              !originalRoute.address),
      subform: originalRoute.arrivalTime && originalRoute.arrivalDate ? '?form=finished' : '',
    }
  }

  if ( !route.finishRouteAddressRequired) {
    return {
      ...route,
      missingRequiredFields: (!originalRoute.arrivalTime || !originalRoute.arrivalDate),
      subform: ''
    }
  }

  return {
    ...route,
    missingRequiredFields: (!originalRoute.arrivalTime || !originalRoute.arrivalDate || !originalRoute.address),
    subform: '',
  }
}

function filterRoute(route: IncompleteRoutes, towingRecord: TowingRecord, useExtendedRoutes: boolean) {
  const shouldIgnoreFinishedTimestampOnRoute = 
    [TowingRecordType.default, TowingRecordType.autoliitto].includes(towingRecord.type) && !useExtendedRoutes;

  if(shouldIgnoreFinishedTimestampOnRoute) {
    return !route.done
  } 

  //When towing record should only have 3 routes, start, arrival, end.
  if (route.routeId === 'finish' && disableFinishRoute(towingRecord, useExtendedRoutes)) {
    return false
  }

  return route.missingRequiredFields
}

